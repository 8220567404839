<template>
  <div class="exam-layout full-width full-height">
    <aside class="exam-information items-center justify-around q-px-sm">
      <div class="title flex row full-width justify-center">
        <q-btn
          dense
          flat
          icon="chevron_left"
          :to="{
            name: 'training-page',
            query: {
              courseId: courseId,
              trainingId: trainingId,
              postId: postId,
            },
          }"
        />
        <div class="icon-exam q-mr-sm">
          <BadgeType iconBadge="book" typeBadge="Exam" />
        </div>
        <h4>Avaliação</h4>
      </div>
      <div
        class="exam-about full-width flex column justify-around items-start q-pa-sm"
      >
        <p>{{ Examdata.title }}</p>
        <small>
          <span>
            <q-img width="20px" height="16px" :src="teacherIcon" />
          </span>
          {{ Examdata.tutor }}
        </small>
        <p>Quantidade de Questões: {{ Examdata.number_questions }}</p>
        <p>{{ Examdata.points }} pontos</p>
      </div>
      <div
        class="exam-timer full-width flex column justify-center items-center q-pa-md"
      >
        <p>Tempo de avaliação:</p>
        <div class="row items-center" style="flex-wrap: unset">
          <p class="exam-time flex items-center justify-center">
            {{ countDownDisplay[0] }}
          </p>
          <span> : </span>
          <p class="exam-time flex items-center justify-center">
            {{ countDownDisplay[1] }}
          </p>
          <span> : </span>
          <p class="exam-time flex items-center justify-center">
            {{ countDownDisplay[2] }}
          </p>
        </div>
      </div>
      <div class="exam-orientation column full-width q-pa-sm">
        <h6>Orientações:</h6>
        <p
          class="orientation-items"
          v-for="orientation in Examdata.orientations"
          :key="orientation"
        >
          {{ orientation }}
        </p>
      </div>
      <div class="action-btn full-width">
        <q-btn
          :disable="!isAllQuestionsAnswered"
          class="btn-finish"
          unelevated
          label="Entregar prova"
          @click="handleFinishExam"
        />
        <q-btn-dropdown
          class="btn-dropdown"
          dropdown-icon="menu"
          flat
          color="default-pink"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="handleDialog('description')"
            >
              <q-item-section>
                <q-item-label>Descrição</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="handleDialog('orientation')"
            >
              <q-item-section>
                <q-item-label>Orientações</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </aside>
    <main class="exam-content q-pa-sm">
      <div
        class="flex column items-end justify-evenly full-height q-px-md"
        v-if="isShowConcluded"
      >
        <div class="full-width q-pa-sm text-center">
          <h4>Você já finalizou esse Quiz</h4>
          <h6 v-if="isShowScore">
            Sua nota foi {{ nota.toFixed(2).replaceAll(".", ",") }}
          </h6>
          <h6 v-else-if="isOpenquestion">
            Por favor aguarde que o seu tutor corrija a sua avaliação!
          </h6>

          <q-btn
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            dense
            label="Voltar"
            v-close-popup
            :to="{
              name: 'training-page',
              query: {
                courseId: courseId,
                trainingId: trainingId,
                postId: postId,
              },
            }"
          />
        </div>
      </div>
      <div
        class="flex column items-end justify-evenly full-height q-px-md"
        v-else-if="isShowOrientation"
      >
        <div
          v-if="isDescription"
          class="exam-about full-width flex column justify-around items-start q-pa-sm"
        >
          <p>{{ Examdata.title }}</p>
          <small>
            <span>
              <q-img width="20px" height="16px" :src="teacherIcon" />
            </span>
            {{ Examdata.tutor }}
          </small>
          <p>{{ Examdata.points }} pontos</p>
        </div>
        <div
          v-if="isOrientation"
          class="exam-orientation column full-width q-pa-sm"
        >
          <h6>Orientações:</h6>
          <p
            class="orientation-items"
            v-for="orientation in Examdata.orientations"
            :key="orientation"
          >
            {{ orientation }}
          </p>
        </div>
        <div v-if="isEndExam" class="full-width q-pa-sm">
          <h6>Tem certeza que deseja finalizar a avaliação?</h6>
        </div>
        <div v-if="!isStarted">
          <q-btn
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            dense
            label="Voltar"
            v-close-popup
            :to="{
              name: 'training-page',
              query: {
                courseId: courseId,
                trainingId: trainingId,
                postId: postId,
              },
            }"
          />
          <q-btn
            class="q-ml-md"
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            dense
            label="Iniciar avaliação"
            v-close-popup
            @click="handleExam"
          />
        </div>
        <div v-else>
          <q-btn
            v-if="Examdata.timer > 0"
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            dense
            label="retornar"
            v-close-popup
            @click="showQuestions"
          />
          <q-btn
            v-if="isAllQuestionsAnswered || Examdata.timer <= 0"
            class="q-ml-md"
            color="default-pink"
            text-color="white"
            no-caps
            unelevated
            dense
            label="Encerrar Avaliação"
            @click="finishExam"
            v-close-popup
          />
        </div>
      </div>
      <TabDefault
        v-else
        :tab="1"
        :label-tab="Examdata.question"
        :btn-new-tab="false"
        :showContentTop="true"
        :initialTab="1"
        :checkedTabs="checkedQuestions"
      >
        <template v-slot:cards-tabs>
          <div
            class="content-tabs"
            v-for="(item, questionPosition) in Examdata.question"
            :key="item.question.id"
            :id="item.question.id"
          >
            <div class="questioncontent q-pa-md">
              <h3>{{ item.question.title }}</h3>
              <p>{{ item.question.description_question }}</p>
              <div
                class="card-question"
                v-if="item.question.option_type_id == 1"
              >
                <div
                  v-for="option in item.question.options"
                  :key="option.questionOptionId"
                  :class="[
                    option.checks_for_student
                      ? 'card-option-selected'
                      : 'card-option',
                    'row items-center q-pa-sm',
                  ]"
                  @click="
                    selectAnswer(questionPosition, option.questionOptionId)
                  "
                >
                  <h6>{{ option.letter }}</h6>
                  <p>{{ option.description_option }}</p>
                </div>
              </div>
              <div
                v-else-if="item.question.option_type_id == 2"
                class="card-question"
              >
                <q-checkbox
                  :class="[
                    option.checks_for_student
                      ? 'card-option-selected'
                      : 'card-option',
                    'row items-center q-pa-sm',
                  ]"
                  v-for="option in item.question.options"
                  :key="option.questionOptionId"
                  dense
                  @click="selectMultiAnswer(questionPosition)"
                  v-model="option.checks_for_student"
                  :false-value="false"
                  :val="option.checks_for_student"
                  :label="option.description_option"
                  color="teal"
                >
                </q-checkbox>
              </div>
              <div v-else class="card-question">
                <q-input
                  v-model="item.question.answer.answers"
                  class="col-12"
                  clearable
                  clear-icon="close"
                  dense
                  color="default-pink"
                  placeholder="Digite sua resposta..."
                  outlined
                  autogrow
                  :rules="[(val) => val != '' || 'Campo obrigatório']"
                />
              </div>
              <!-- <div class="flex row justify-start items-center q-mt-md">
                <label
                  :for="'anexo' + questionPosition"
                  class="anexo-btn q-py-sm q-px-md"
                >
                  <span>
                    <q-img
                      src="../../assets/icons/clip.svg"
                      height="20px"
                      width="18px"
                    />
                  </span>
                  Adicionar anexo
                </label>
                <span v-if="item.file" class="q-ml-md">
                  <q-icon name="description" size="md" color="blue" />
                  {{ item.question.file.name }}
                </span>
                <input
                  style="display: none"
                  ref="refFileInput"
                  :id="'anexo' + questionPosition"
                  @change="(e) => onEventFilePicked(e, questionPosition)"
                  type="file"
                  name="upload"
                />
              </div> -->
              <div class="flex justify-end">
                <DefaultBtn
                  :showIcon="false"
                  titleButton="Confirmar resposta"
                  @click="confirmAnswer(questionPosition)"
                />
              </div>
            </div>
          </div>
        </template>
      </TabDefault>
      <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </main>
  </div>
</template>

<script>
//----IMAGES
import teacherIcon from "@/assets/icons/teacher.svg";

//----COMPONENTS
import BadgeType from "@/components/BadgeType.vue";
import TabDefault from "@/components/tabs/DefaultTabs.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";

//----SERVICES
import TrailDataServices from "@/services/TrailDataServices";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Exam",
  components: {
    BadgeType,
    TabDefault,
    DefaultBtn,
  },
  setup() {
    const $q = useQuasar();
    const refFileInput = ref(null);
    const refTabs = ref(null);
    let isDescription = ref(true);
    let isOrientation = ref(true);
    let isShowOrientation = ref(true);
    let isShowConcluded = ref(false);
    let isStarted = ref(false);
    let isShowScore = ref(true);
    let isOpenquestion = ref(false);
    let isAllQuestionsAnswered = ref(false);
    let isEndExam = ref(false);
    let nota = ref(0);
    let countDownDisplay = ref(["00", "00", "00"]);
    let Examdata = ref({
      title: "",
      tutor: "",
      points: 0,
      timer: 0,
      number_questions: 0,
      orientations: [
        "Responda todas as questões para entregar a prova.",
        "As respostas são salvas a cada confirmação.",
      ],
      question: [],
    });
    let checkedQuestions = ref([]);

    let router = new useRouter();
    let route = new useRoute();
    let courseId = route.query.courseId;
    let trainingId = route.query.trainingId;
    let postId = route.query.postId;
    let quizId = route.query.quizId;
    let isConcluded = route.query.concluded;
    let trailDataServices = new TrailDataServices();
    let loading = ref(false);
    let answerIdInPage = ref(null);
    let countQuestion = ref(1);
    let openAnswer = ref("");
    let openAnswerArray = ref([]);

    async function _getQuiz() {
      loading.value = true;

      // "option_type_id": 2,
      // "option_type": "Multipla Escolha",
      let response = await trailDataServices.getEvaluation(
        courseId,
        trainingId,
        quizId
      );

      let quiz = response.data;
      isShowScore.value = quiz.show_score;

      if (isConcluded == "true") {
        isShowConcluded.value = true;
        Examdata.value.title = quiz.title;
        Examdata.value.points = quiz.max_score;
        Examdata.value.number_questions = quiz.number_questions;
        Examdata.value.tutor = quiz.tutor;
        finishExam();
      } else {
        Examdata.value.title = quiz.title;
        Examdata.value.tutor = quiz.tutor;
        Examdata.value.timer = quiz.timer > 0 ? 0 : -1;
        Examdata.value.points = quiz.max_score;
        Examdata.value.number_questions = quiz.number_questions;
      }
      loading.value = false;
    }

    function convertAnswers(quiz) {
      if (quiz.option_type_id == 3) {
        if (quiz.answer == null || quiz.answer == "") {
          quiz.answer = {
            answers: "",
          };
        } else {
          quiz.answer = JSON.parse(quiz.answer);
        }
      }
    }

    async function _startQuiz() {
      let response = await trailDataServices
        .startEvaluation(courseId, trainingId, quizId)
        .catch((error) => {
          loading.value = false;
          alert(error.response.data);
        });
      let quantityQuestionAnswered = 0;

      if (response.status == 200) {
        response.data.forEach((quiz) => {
          quiz.id = countQuestion.value;
          quiz.title = "Questão " + countQuestion.value;
          quiz.file = "";

          // if(quiz.option_type_id == 3){
          //   if(quiz.answer == null){
          //     quiz.answer = {
          //       'answer': ''
          //     };
          //   }else{
          //     quiz.answer = JSON.parse(quiz.answer)
          //   }
          // }
          convertAnswers(quiz);
          Examdata.value.question.push({
            question: quiz,
          });

          var answered = quiz.options.filter((option) => {
            return option.checks_for_student;
          });
          if (answered != null) {
            quantityQuestionAnswered++;
          }
          countQuestion.value = countQuestion.value + 1;
        });

        //_countDowWatch();
      } else if (response.status == 201) {
        await finishExam();
      }

      isAllQuestionsAnswered.value =
        quantityQuestionAnswered == Examdata.value.number_questions
          ? true
          : false;
    }

    async function _answerQuiz(index) {
      let answers = Examdata.value.question[index].question.answer;
      let answer_id = Examdata.value.question[index].question.answer_id;
      let isAnswered = Examdata.value.question[index].question.isAnswered;

      let data = {
        answers: [],
        open_answers: {
          Answer: "",
        },
      };

      let options = Examdata.value.question[index].question.options.filter(
        (option) => {
          return option.checks_for_student;
        }
      );

      if (Examdata.value.question[index].question.option_type_id == 3) {
        data.open_answers.Answer =
          Examdata.value.question[index].question.answer.answers;
      }

      options.forEach((option) => {
        data.answers.push(parseInt(option.questionOptionId));
      });

      let response = await trailDataServices.answerQuiz(
        courseId,
        trainingId,
        quizId,
        answer_id,
        data,
        isAnswered
      );

      if (response.status == 200) {
        if (!isAnswered) {
          response.data.id = countQuestion.value;
          response.data.title = "Questão " + countQuestion.value;
          response.data.answer = "";
          response.data.file = "";

          // if(response.option_type_id == 3){
          //   if(response.answer == null){
          //     response.answer = {
          //       'answers': ''
          //     };
          //   }else{
          //     response.answer = JSON.parse(response.answer)
          //   }
          // }
          convertAnswers(response.data);

          Examdata.value.question.push({
            question: response.data,
          });
          countQuestion.value = countQuestion.value + 1;
        }
      } else if (response.status == 201) {
        //  if(response.data == "Evaluation finalizado!"){
        //   finishExam();
        //  }
      }
    }

    // function onEventFilePicked(event, question) {
    //   const files = event.target.files;
    //   const image = files[0];
    //   const filename = files[0].name;

    //   if (filename.lastIndexOf(".") <= 0) {
    //     return alert("Por favor adicione um arquivo válido");
    //   }

    //   const fileReader = new FileReader();
    //   fileReader.addEventListener("load", (event) => {
    //     //fileReader.result
    //   });

    //   Examdata.value.question[question].question.file = files[0];

    //   fileReader.readAsDataURL(files[0]);
    // }

    // const sendEventRemoveImage = () => {
    //   imageUrl.value = "";
    //   refFileInput.value = null;
    //   file.value = "";
    // };

    function handleDialog(type) {
      if (type == "orientation") {
        isOrientation.value = true;
        isDescription.value = false;
      } else {
        isOrientation.value = false;
        isDescription.value = true;
      }
      isShowOrientation.value = true;
    }

    async function handleExam() {
      loading.value = true;
      await _startQuiz();
      _countDowWatch();
      showQuestions();
      loading.value = false;
    }

    function handleFinishExam() {
      isShowOrientation.value = true;
      isEndExam.value = true;
    }

    async function finishExam() {
      let hasOpenQuestion;

      Examdata.value.question.find((question) => {
        if (question.option_type_id == 3) {
          hasOpenQuestion = false;
        } else {
          hasOpenQuestion = true;
        }
      });

      if (hasOpenQuestion) {
        var score = await trailDataServices.scoreQuiz(
          courseId,
          trainingId,
          quizId
        );
        nota.value = score.data;
        isOpenquestion.value = false;
      } else {
        isShowScore.value = false;
        isOpenquestion.value = true;
      }

      /* var score = await trailDataServices.scoreQuiz(
        courseId,
        trainingId,
        quizId
      );
      nota.value = score.data; */

      isShowOrientation.value = false;
      isShowConcluded.value = true;
      isEndExam.value = true;
    }

    function showQuestions() {
      isStarted.value = true;
      isShowOrientation.value = false;
      isDescription.value = false;
      isOrientation.value = false;
      isEndExam.value = false;

      if (
        checkedQuestions.value.length == Examdata.value.question.length &&
        isStarted.value == true
      ) {
        isAllQuestionsAnswered.value = ref(true);
      }
    }

    function selectAnswer(question, value) {
      Examdata.value.question[question].question.answer = value;

      Examdata.value.question[question].question.options.filter((option) => {
        if (option.questionOptionId != value) {
          option.checks_for_student = false;
        } else {
          option.checks_for_student = true;
        }
      });
    }

    function selectMultiAnswer(question) {
      let options = Examdata.value.question[question].question.options.filter(
        (option) => {
          return option.checks_for_student;
        }
      );
      let values = "";
      options.forEach((option) => {
        values += option.questionOptionId + ",";
      });
      values = values.substring(0, values.length - 1);

      Examdata.value.question[question].question.answer = values;
    }

    /* function getLocaleOpenAnswer(question) {
      console.log(question);
      let storageAnswers = [];
      question.forEach((quest) => {
        if (quest.option_type_id === 3 && quest.answer != null) {
          storageAnswers.push({
            answer: JSON.parse(quest.answer),
            answerId: quest.answer_id,
          });
        }
      });

      console.log(storageAnswers);

            let storageAnswers = [];
      question.forEach((quest) => {
        if (quest.option_type_id === 3 && quest.answer != null) {
          storageAnswers.push({
            answer: JSON.parse(quest.answer),
            answerId: quest.answer_id,
          });
          console.log(storageAnswers);
        }
      });


      storageAnswers.forEach((storageAnswer) => {
        if (storageAnswer.answerId) {
        }
      });
    } */

    async function confirmAnswer(question) {
      loading.value = true;

      const closedQuestionValidation = Examdata.value.question[
        question
      ].question.options.find((option) => {
        return option.checks_for_student ? true : false;
      });

      const openQuestionValidation =
        Examdata.value.question[question].question.option_type_id == 3 &&
        Examdata.value.question[question].question.answer.answers == "";

      if (
        !closedQuestionValidation &&
        (Examdata.value.question[question].question.option_type_id == 1 ||
          Examdata.value.question[question].question.option_type_id == 2)
      ) {
        $q.notify({
          message: "Marque um alternativa",
          color: "red-9",
          position: "top",
          timeout: 3000,
        });
      } else if (openQuestionValidation) {
        $q.notify({
          message: "Escreva uma resposta",
          color: "red-9",
          position: "top",
          timeout: 3000,
        });
      } else {
        await _answerQuiz(question);
        let awnswers = Examdata.value.question.filter((item) => {
          return item.question.answer != "";
        });

        /* getLocaleOpenAnswer(Examdata.value.question); */

        if (Examdata.value.number_questions == Examdata.value.question.length) {
          isAllQuestionsAnswered.value = ref(true);
        }

        localStorage.setItem("answers", JSON.stringify(awnswers));
        checkedQuestions.value.push(
          Examdata.value.question[question].question.id
        );
      }
      loading.value = false;
    }

    function _countDowWatch() {
      if (isShowConcluded.value == true) {
        return;
      }
      let countdown = setInterval(() => {
        if (Examdata.value.timer >= 0) {
          _convertCountDown(Examdata.value.timer);
          Examdata.value.timer++;
        } else {
          _convertCountDown(0);
          // clearInterval(countdown);
          isShowOrientation.value = true;
          isEndExam.value = true;
        }
      }, 1000);
    }

    function _convertCountDown(value) {
      if (value > 0) {
        let h = Math.floor(value / 3600);
        let m = Math.floor((value % 3600) / 60);
        let s = Math.floor((value % 3600) % 60);

        countDownDisplay.value[0] = h >= 10 ? h : "0" + h;
        countDownDisplay.value[1] = m >= 10 ? m : "0" + m;
        countDownDisplay.value[2] = s >= 10 ? s : "0" + s;
      } else {
        countDownDisplay.value[0] = "00";
        countDownDisplay.value[1] = "00";
        countDownDisplay.value[2] = "00";
      }

      /* let minuts = Math.floor(value / 60);
      let seconds = value % 60;
      countDownDisplay.value[0] = minuts >= 10 ? minuts : "0" + minuts;
      countDownDisplay.value[1] = seconds >= 10 ?  Math.Round(seconds) : "0" + Math.Round(seconds);*/
    }

    function _getSaveData() {
      let answers = JSON.parse(localStorage.getItem("answers"));

      if (answers != null) {
        Examdata.value.question.map((item) => {
          answers.map((answer) => {
            if (answer.question.id == item.question.id) {
              checkedQuestions.value.push(item.question.id);
              item.question.answer = answer.question.answer;
              item.question.question = answer.question.question;
            }
          });
        });
      }
    }

    onMounted(async () => {
      await _getQuiz();
      _convertCountDown(Examdata.value.timer);
      _getSaveData();
    });

    return {
      //----IMAGES
      teacherIcon,

      //----CONSTANTS
      courseId,
      trainingId,
      postId,
      nota,

      //----VARIABLES
      isShowOrientation,
      isShowConcluded,
      isOrientation,
      isDescription,
      isStarted,
      isShowScore,
      countDownDisplay,
      isAllQuestionsAnswered,
      isEndExam,
      refFileInput,
      refTabs,
      checkedQuestions,
      Examdata,
      loading,
      openAnswer,
      isOpenquestion,

      //----FUNCTION
      handleDialog,
      /*       sendEventRemoveImage,
      onEventFilePicked, */
      handleExam,
      showQuestions,
      handleFinishExam,
      finishExam,
      selectAnswer,
      answerIdInPage,
      confirmAnswer,
      selectMultiAnswer,
    };
  },
};
</script>

<style lang="scss" scoped>
.exam-layout {
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.exam-information {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title,
  .exam-about,
  .exam-orientation {
    display: none;
  }

  @media (min-width: 800px) {
    .title,
    .exam-about,
    .exam-orientation {
      display: flex;
    }
  }
}

.title {
  .icon-exam {
    width: 45px;
    height: 45px;
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
  }
}

.exam-about {
  min-height: 20%;
  border: 1px solid #489b88;
  border-radius: 20px;
  /* padding: 1% 2%; */
  p {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 22px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #686868;
  }
  small {
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 16px;
    color: #00000073;
  }
}

.exam-timer {
  background: #f7f7f7;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  > div {
    min-width: 130px;
    span {
      padding: 0 4%;
    }
  }

  p {
    margin: 0;
  }
  .exam-time {
    padding: 2%;
    background: #fdfdfd;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.37rem;
    line-height: 27px;
    letter-spacing: 0.15rem;
    color: #000000;
  }
}

.exam-orientation {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
    0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 0.87rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #686868;
  }

  .orientation-items {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #686868;

    border-bottom: 1px solid #e5e5e5;
    padding: 2%;
  }
}

.action-btn {
  display: flex;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: center;
  }

  .btn-finish {
    background: #54aa95;
    color: white;
    box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
      0px 0.85px 3px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
  }
  .btn-dropdown {
    display: flex;

    @media (min-width: 800px) {
      display: none;
    }
  }
}

.exam-content {
  flex: 4;
  overflow: auto;
  .questioncontent {
    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    overflow: auto;
    background: #f7f7f7;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 20px;

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 16px;
      color: #000000;
    }

    p {
      margin: 2% 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }

    .anexo-btn {
      margin-bottom: 1%;

      background: #fdfdfd;
      color: #fe5268;
      border: 1px solid #fe5268;
      box-sizing: border-box;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
        0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
      border-radius: 50px;
      cursor: pointer;
      @media (min-width: 800px) {
        margin-bottom: 0%;
      }
    }
  }
  .card-question {
    display: flex;
    flex-direction: column;
    /* min-height: 60%; */
  }

  .card-option {
    background: #fdfdfd;
    margin-bottom: 1.5%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
      0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }
  }

  .card-option-selected {
    border: 2px solid #489b88;
    margin-bottom: 1.5%;
    box-sizing: border-box;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #489b88;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
}
</style>
